import './index.scss';

import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { useLocation } from '@modern-js/runtime/router';
import { debounce } from 'lodash-es';
import { useMemoizedFn } from 'ahooks';
import { BaseNode, Editor, ReactEditor, Transforms } from '@hkgai/slate-plugin/dist/lib/delta';
import { Editable, EDITOR_EVENT, useMakeEditor, LOG_LEVEL } from '@hkgai/slate-plugin/dist/lib/core';
import {
  BoldPlugin,
  StrikeThroughPlugin,
  ItalicPlugin,
  UnderLinePlugin,
  ClipboardPlugin,
  DividingLinePlugin,
  HeadingPlugin,
  HyperLinkPlugin,
  IndentPlugin,
  InlineCodePlugin,
  OrderedListPlugin,
  ParagraphPlugin,
  QuoteBlockPlugin,
  TablePlugin,
  UnorderedListPlugin,
  ProofreadLinePlugin,
} from '@hkgai/slate-plugin/dist/lib/plugins';
import { getEditorContent, getNewParagraphNode } from '../../util';
import { schema } from './schema';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import useLocale from '@/hooks/useLocale';

export const NullNode: BaseNode[] = [getNewParagraphNode('')];

export interface DocEditorRef {
  resetEditorContent: () => void;
}

interface DocEditorProp {
  // eslint-disable-next-line react/no-unused-prop-types
  cCId?: string;
}

// eslint-disable-next-line no-empty-pattern
const DocEditor = forwardRef<DocEditorRef, DocEditorProp>(({}, ref) => {
  const locale = useLocale();
  const location = useLocation();
  const { setEditor, setEditorKit, setEditableRef } = useWritingContext();
  const [{ editorReadonly, isAIWork }, { setEditorContent }] = useModel(EditorStore);
  const editableRef = useRef<HTMLDivElement>(null);
  const editor = useMakeEditor(schema, NullNode);

  useMemo(() => {
    editor.logger.set(LOG_LEVEL.DEBUG);
    editor.plugin.register(
      new BoldPlugin(editor),
      new StrikeThroughPlugin(editor),
      new ItalicPlugin(editor),
      new UnderLinePlugin(editor),
      new ParagraphPlugin(editor),
      new ClipboardPlugin(editor),
      new HeadingPlugin(editor),
      new QuoteBlockPlugin(editor),
      new HyperLinkPlugin(editor, false),
      new InlineCodePlugin(editor),
      new OrderedListPlugin(editor),
      new UnorderedListPlugin(editor),
      new DividingLinePlugin(editor),
      new IndentPlugin(editor),
      new TablePlugin(editor, false),
      new ProofreadLinePlugin(editor),
      // new ToolBarTriggerPlugin(editor, () => {
      //   return <ToolBarTrigger />;
      // }),
    );
  }, [editor]);

  useImperativeHandle(ref, () => ({
    resetEditorContent,
  }));

  const resetEditorContent = () => {
    Transforms.delete(editor.raw, {
      at: {
        anchor: Editor.start(editor.raw, []),
        focus: Editor.end(editor.raw, []),
      },
    });
  };

  const updateText = useMemoizedFn(
    debounce(() => {
      setEditorContent(getEditorContent(editor));
    }, 500),
  );

  useEffect(() => {
    editor.event.on(EDITOR_EVENT.CONTENT_CHANGE, updateText);
    return () => {
      editor.event.off(EDITOR_EVENT.CONTENT_CHANGE, updateText);
    };
  }, [editor.event, updateText]);

  useEffect(() => {
    setEditor?.(editor.raw);
    setEditorKit?.(editor);
    setEditableRef?.(editableRef);
  }, [editor]);

  useEffect(() => {
    editor.raw.children = NullNode;
    ReactEditor.focus(editor.raw);
  }, [location]);

  return (
    <div ref={editableRef} className="editor-container relative" id="editor-container">
      <Editable editor={editor} placeholder={isAIWork ? '' : locale['slate.editor.placeholder']} readonly={editorReadonly} />
    </div>
  );
});

export default DocEditor;
