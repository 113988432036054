import { Editor, Transforms, ReactEditor, Location, BaseRange } from '@hkgai/slate-plugin/dist/lib/delta';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useModel, useStaticModel } from '@modern-js/runtime/model';
import { Message } from '@arco-design/web-react';
import dayjs from 'dayjs';
import { getClosestBlockPath, getNextBlockNode } from '@hkgai/slate-plugin/dist/lib/utils';
import Generating, { GeneratingRef } from '../generating';
import GenerationResult from '../generate-result';
import GenerateMenuToolbar from '../generate-menu-toolbar';
import AIInputBox from '../ai-input-box';
import EscKeyListener from '../esc-key-listener';
import { calculateToolbarTop, getEditorContent, getNewParagraphNode, getReferencesContent } from '@/views/writing/util';
import { continuationParagraph, expansionParagraph, fullArticle, rewriteParagraph, translateParagraph, summarizeParagraph, abortGenerate } from '@/api/event-source_api';
import { AIWorkStatus, WritingType, WritingTypeText } from '@/type/ui';
import { useWritingContext } from '@/context/writingContext';
import EditorStore, { WriteEssayParam } from '@/store/editorStore';
import { setGenerateParam } from '@/utils/generateParam';
import useLocale from '@/hooks/useLocale';
import { GlobalContext } from '@/routes/context';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';

// 计算浮动bar的top timer
let realTimeCalculationTimer: NodeJS.Timer;

export interface GenerateWrapperRef {
  handleWriteEssay: (param?: WriteEssayParam) => void;
}

interface GenerateWrapperProps {
  cancel?: () => void;
}

const GenerateWrapper = forwardRef<GenerateWrapperRef, GenerateWrapperProps>(({ cancel }, ref) => {
  const locale = useLocale();
  const { collectEvent } = useContext(GlobalContext);

  const generatingRef = useRef<GeneratingRef>(null);
  const { editor, editorKit } = useWritingContext();
  const [
    { isAIWork, writingType, workStatus, writeEssayParam, visibleGInput, visibleGLoading, visibleGResult, generatingText },
    { changeWorkStatus, setWritingType, setGeneratingText, resetGeneratingText, setIsAIWork, changeEditorSelection, setWriteEssayParam },
  ] = useModel(EditorStore);

  // https://modernjs.dev/guides/topic-detail/model/use-model.html#%E4%BD%9C%E4%B8%BA%E9%9D%99%E6%80%81%E7%8A%B6%E6%80%81%E4%BD%BF%E7%94%A8
  // 暂时解决同步函数里修改store没法马上获取到最新的值
  const [editorStoreState] = useStaticModel(EditorStore);
  const pageName = PageNameConfig.WRITING_PAGE;

  useImperativeHandle(ref, () => ({
    handleWriteEssay,
  }));

  const calculationTop = (autoScroll = true) => {
    if (!editor) {
      cancel?.();
      return;
    }

    ReactEditor.focus(editor);
    const top = calculateToolbarTop(autoScroll);
    if (top === null) {
      // 一旦没有选区了，就要移除timer
      clearInterval(realTimeCalculationTimer);
    }
  };

  const startCalculationTimer = () => {
    clearInterval(realTimeCalculationTimer); //  开定时器前先清除定时器，避免神仙打架
    realTimeCalculationTimer = setInterval(calculationTop, 100);
  };

  const asyncCalculateToolbarTop = (autoScroll = true) => {
    setTimeout(() => {
      calculateToolbarTop(autoScroll);
    }, 10);
  };

  //  开始写作  ai input
  const handleWriteEssay = async (param?: WriteEssayParam) => {
    changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    resetGeneratingText();

    // 用于计算插入位置
    let insertAtPath = [0, 0];
    let insertAtOffset = 0;
    const range = {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    };

    // const content = '';
    const selection = editorStoreState.editorSelection;

    if (selection) {
      const [insertStartPoint, insertEndPoint] = Editor.edges(editor!, selection);
      range.anchor = insertStartPoint;
      range.focus = insertEndPoint;

      insertAtPath = insertStartPoint.path;
      insertAtOffset = insertStartPoint.offset;

      // 然后设置光标位置，例如到文档的开始位置
      ReactEditor.focus(editor!);
      Transforms.select(editor!, selection);
      asyncCalculateToolbarTop();
    }

    const { content, style, lang, wordNum, references, fileText, user_ref, sence, enable_network_search } = param || writeEssayParam;
    let brainstorm = '';

    // 获取引用的内容
    if (references!.length > 0) {
      brainstorm = await getReferencesContent(references!);
    }

    // 增加file文件内容
    if (fileText!.length > 0) {
      brainstorm += fileText!.join(' ');
    }

    let tmpGText = '';

    const baseParams = {
      event: WritingPageEvent.CLICK_AI_WRITING,
      params: {
        pageName,
        moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
        action: WritingTypeText[WritingType.AIWriting],
        writeStyle: style || '',
        writeLang: lang || '',
        writeWordNum: `${wordNum}`,
        writeBrainstorm: brainstorm,
      },
    };
    const startTime = dayjs().unix();
    const plaintext = getEditorContent(editorKit!).plain;

    fullArticle(
      content!,
      lang!,
      wordNum!,
      brainstorm,
      (text, status) => {
        if (status === AIWorkStatus.CHATError) {
          handleError();
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'fail',
              },
            },
          ]);
          return;
        }

        if (status === AIWorkStatus.COMPLETED) {
          setGenerateParam({ result: tmpGText });
          range.focus = { path: insertAtPath, offset: insertAtOffset };
          ReactEditor.focus(editor!);
          Transforms.select(editor!, range);
          changeEditorSelection(range);
          changeWorkStatus?.(AIWorkStatus.COMPLETED);
          clearInterval(realTimeCalculationTimer); // 停止计算
          asyncCalculateToolbarTop();
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'success',
              },
            },
          ]);
          return;
        }

        changeWorkStatus?.(AIWorkStatus.WORKING);
        setGeneratingText?.(text);
        tmpGText += text;

        const needInsertText = text.split('\n');

        needInsertText.forEach((tmpText: string, index: number) => {
          if (index === 0 && tmpText !== '') {
            Transforms.insertText(editor!, tmpText, { at: { path: insertAtPath, offset: insertAtOffset } });
            insertAtOffset += tmpText.length;
          }
          if (index > 0) {
            Transforms.insertNodes(
              editor!,
              {
                children: [{ text: tmpText }],
              },
              { at: { path: insertAtPath, offset: insertAtOffset } },
            );

            insertAtPath = [insertAtPath[0] + 1, 0];
            insertAtOffset = tmpText.length;
          }
        });

        // 然后设置光标位置，例如到文档的开始位置
        ReactEditor.focus(editor!);
        const newSelection = { anchor: { path: insertAtPath, offset: insertAtOffset }, focus: { path: insertAtPath, offset: insertAtOffset } };
        Transforms.select(editor!, newSelection);
        changeEditorSelection(newSelection);
        asyncCalculateToolbarTop();
      },
      plaintext,
      user_ref,
      sence,
      enable_network_search,
    );
  };

  // 终端生成时候的回调处理
  const handleCancel = () => {
    ReactEditor.focus(editor!);
    if (editorStoreState.editorSelection) {
      Transforms.select(editor!, editorStoreState.editorSelection);
    }
    clearInterval(realTimeCalculationTimer);
    abortGenerate();

    if (generatingText && generatingText.length > 0) {
      changeWorkStatus?.(AIWorkStatus.COMPLETED);
    } else {
      changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
      setIsAIWork(false);
      setWritingType(WritingType.UNKNOWN);
    }
  };

  //   写作报错
  const handleError = () => {
    // 模型开小差后，避免 focus 打架
    clearInterval(realTimeCalculationTimer);
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    setIsAIWork(false);
    setWritingType(WritingType.UNKNOWN);
    Message.warning(locale['handle.serve.error.message']);
  };

  useEffect(() => {
    return () => {
      clearInterval(realTimeCalculationTimer);
    };
  }, []);

  //   扩写/改写/翻译
  const generateByToolBar = async (content: string, w: WritingType, l?: string, p?: string, startIdx?: number, endIdx?: number, enableNetworkSearch?: boolean) => {
    let tmpGText = ''; // 当前会话生成字数
    const eventName = w === WritingType.EXPANSION ? WritingPageEvent.CLICK_EXPAND : WritingPageEvent.CLICK_REWRITE;
    const baseParams = {
      event: l ? WritingPageEvent.CLICK_TRANSLATE : eventName,
      params: {
        pageName,
        moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
        action: WritingTypeText[w || WritingType.UNKNOWN],
      },
    };
    const startTime = dayjs().unix();
    const generateCallBack = (text: string, status: AIWorkStatus | undefined) => {
      if (status === AIWorkStatus.CHATError) {
        handleError();
        collectEvent?.([
          {
            ...baseParams,
            params: {
              ...baseParams.params,
              tokens: `${tmpGText.length}`,
              duration: `${dayjs().unix() - startTime}`,
              status: 'fail',
            },
          },
        ]);
        return;
      }

      if (status === AIWorkStatus.COMPLETED) {
        setGenerateParam({ result: tmpGText });
        changeWorkStatus?.(AIWorkStatus.COMPLETED);
        clearInterval(realTimeCalculationTimer); // 停止计算
        collectEvent?.([
          {
            ...baseParams,
            params: {
              ...baseParams.params,
              tokens: `${tmpGText.length}`,
              duration: `${dayjs().unix() - startTime}`,
              status: 'success',
            },
          },
        ]);
        return;
      }
      setGeneratingText?.(text);
      tmpGText += text;
      ReactEditor.focus(editor!);
      editorStoreState.editorSelection && Transforms.select(editor!, editorStoreState.editorSelection);
    };

    changeWorkStatus?.(AIWorkStatus.WORKING);

    changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    resetGeneratingText?.();
    const plaintext = getEditorContent(editorKit!).plain?.replaceAll('\n', '');

    let brainstorm = '';
    if ([WritingType.EXPANSION, WritingType.REWRITE, WritingType.SUMMARIZE, WritingType.TRANSLATE].includes(w)) {
      // 获取引用的内容
      const { references = [], fileText = [] } = writeEssayParam;
      if (references.length) {
        brainstorm = await getReferencesContent(references);
      }

      // 增加file文件内容
      if (fileText.length) {
        brainstorm += fileText.join(' ');
      }
    }
    // 扩写
    if (w === WritingType.EXPANSION) {
      expansionParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx);
    }
    // 改写
    if (w === WritingType.REWRITE) {
      rewriteParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx, enableNetworkSearch);
    }
    // 翻译
    if (w === WritingType.TRANSLATE) {
      translateParagraph(content, l || '', generateCallBack, plaintext, brainstorm, p, startIdx, endIdx);
    }
    // 总结
    if (w === WritingType.SUMMARIZE) {
      summarizeParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx);
    }
  };

  //   续写
  const generateByContinuation = async (content: string, w: WritingType, p?: string, startIdx?: number, endIdx?: number, newSelection?: BaseRange, enableNetworkSearch?: boolean) => {
    resetGeneratingText();
    // 用于计算插入位置
    let insertAtPath = [0, 0];
    let insertAtOffset = 0;
    const range = {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    };
    const selection = newSelection || editorStoreState.editorSelection!;

    if (selection) {
      const [insertStartPoint, insertEndPoint] = Editor.edges(editor!, selection);
      range.anchor = insertEndPoint;
      range.focus = insertEndPoint;

      insertAtPath = insertEndPoint.path;
      insertAtOffset = insertEndPoint.offset;
      // 然后设置光标位置，例如到文档的开始位置
      ReactEditor.focus(editor!);
      Transforms.select(editor!, selection);
      asyncCalculateToolbarTop();
    }
    // 进入等待调取接口等待，同时开始循环计算top的值
    changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    let tmpGText = '';
    const startTime = dayjs().unix();
    const plaintext = getEditorContent(editorKit!).plain?.replaceAll('\n', '');
    // 获取引用的内容
    let brainstorm = '';
    const { references = [], fileText = [] } = writeEssayParam;
    if (references.length) {
      brainstorm = await getReferencesContent(references);
    }

    // 增加file文件内容
    if (fileText.length) {
      brainstorm += fileText.join(' ');
    }

    // 续写另起一行
    Transforms.insertNodes(editor!, getNewParagraphNode(''), { at: { path: insertAtPath, offset: insertAtOffset } });
    insertAtPath = [insertAtPath[0] + 1, 0];
    insertAtOffset = 0;
    range.anchor = {
      path: insertAtPath,
      offset: insertAtOffset,
    };
    continuationParagraph(
      content,
      (text: string, status) => {
        const baseParams = {
          event: WritingPageEvent.CLICK_CONTINUE_WRITING,
          params: {
            pageName,
            moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
            action: WritingTypeText[w || WritingType.UNKNOWN],
          },
        };
        if (status === AIWorkStatus.CHATError) {
          handleError();
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'fail',
              },
            },
          ]);
          return;
        }
        if (status === AIWorkStatus.COMPLETED) {
          clearInterval(realTimeCalculationTimer);
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'success',
              },
            },
          ]);
          setGenerateParam({ result: tmpGText });
          range.focus = { path: insertAtPath, offset: insertAtOffset };
          ReactEditor.focus(editor!);
          Transforms.select(editor!, range);
          changeEditorSelection(range);
          changeWorkStatus?.(AIWorkStatus.COMPLETED);

          console.log('COMPLETED');
          clearInterval(realTimeCalculationTimer);
          asyncCalculateToolbarTop();
          return;
        }

        changeWorkStatus?.(AIWorkStatus.WORKING);
        setGeneratingText?.(text);
        tmpGText += text;
        // 插入生成的内容
        const needInsertText = text.split('\n');
        needInsertText.forEach((tmpText: string, index: number) => {
          if (index === 0 && tmpText !== '') {
            Transforms.insertText(editor!, tmpText, { at: { path: insertAtPath, offset: insertAtOffset } });
            insertAtOffset += tmpText.length;
          }
          if (index > 0) {
            Transforms.insertNodes(editor!, getNewParagraphNode(tmpText), { at: { path: insertAtPath, offset: insertAtOffset } });
            insertAtPath = [insertAtPath[0] + 1, 0];
            insertAtOffset = tmpText.length;
          }
        });

        // 然后设置光标位置，例如到文档的开始位置
        ReactEditor.focus(editor!);
        const newSelection = { anchor: { path: insertAtPath, offset: insertAtOffset }, focus: { path: insertAtPath, offset: insertAtOffset } };
        Transforms.select(editor!, newSelection);
        changeEditorSelection(newSelection);
        asyncCalculateToolbarTop();
      },
      plaintext,
      p,
      brainstorm,
      startIdx,
      endIdx,
      enableNetworkSearch,
    );
  };

  const handleMenuToolbar = (w: WritingType, l?: string, p?: string, network?: boolean) => {
    const selectedContent = Editor.string(editor!, editor!.selection!);

    const [startPoint] = Editor.edges(editor!, editor!.selection!);
    const previousContent = Editor?.string(editor!, { anchor: { path: [0, 0], offset: 0 }, focus: startPoint });
    const startIdx = previousContent.length;
    const endIdx = previousContent.length + selectedContent.length - 1;

    setWriteEssayParam({ content: p || '', lang: l || '', selectedContent, selectedContentStartIdx: startIdx, selectedContentEndIdx: endIdx });
    setIsAIWork?.(true);

    //  续写
    if (w === WritingType.CONTINUATION) {
      generateByContinuation(selectedContent, w, p, startIdx, endIdx, undefined, network);
      startCalculationTimer();
    } else {
      // bar上处理续写以外的 其他action翻译
      generateByToolBar(selectedContent, w, l, p, startIdx, endIdx, network);
      startCalculationTimer();
    }
  };

  useEffect(() => {
    if (writingType === WritingType.AIWriting && workStatus === AIWorkStatus.NOT_WORKING) {
      if (!editor) {
        return;
      }
      clearInterval(realTimeCalculationTimer); // 清除定时器，避免神仙打架
      editor && ReactEditor.focus(editor);
      Transforms.collapse(editor, { edge: 'end' });
      // 无法正常获取选区默认定位到文档末尾
      const endPointOfDoc = Editor.end(editor, []);
      let currentLocation: Location = editor?.selection
        ? editor.selection
        : {
            anchor: endPointOfDoc,
            focus: endPointOfDoc,
          };
      // 当前段落
      const currentBlockPath = getClosestBlockPath(editor, currentLocation);
      // 当前段落内容
      const currentContent = currentBlockPath ? Editor.string(editor, currentBlockPath).trim() : '';
      // 光标移动到当前段落末尾 或者 文档末尾
      const endPoint = currentBlockPath ? Editor.end(editor, currentBlockPath) : endPointOfDoc;
      currentLocation = { anchor: endPoint, focus: endPoint };
      Transforms.select(editor, currentLocation);
      changeEditorSelection(currentLocation);
      // 当前段落有内容，新增一个段落
      if (currentContent) {
        Transforms.insertNodes(
          editor,
          [
            {
              children: [{ text: '' }],
            },
          ],
          { at: currentLocation },
        );
        const newNodePath = getNextBlockNode(editor, { at: currentLocation })?.path || [editor?.children?.length || 0, 0];
        currentLocation = {
          anchor: {
            path: newNodePath,
            offset: 0,
          },
          focus: {
            path: newNodePath,
            offset: 0,
          },
        };
      }
      Transforms.select(editor, currentLocation);
      changeEditorSelection(currentLocation);
      // editor的select操作到影响到浏览器的getSelection好像是个异步的
      asyncCalculateToolbarTop(false);
    }
  }, [writingType, workStatus, editor]);

  return (
    <EscKeyListener onEscKeyDown={handleCancel}>
      <>
        {isAIWork ? (
          <div
            id="generate-wrap"
            className="generate-wrap"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {visibleGInput && (
              <AIInputBox
                closeClick={() => {
                  setIsAIWork(false);
                  setWritingType(WritingType.UNKNOWN);
                  changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
                }}
                sendStart={() => {
                  ReactEditor.focus(editor!);
                  handleWriteEssay();
                  asyncCalculateToolbarTop();
                }}
              />
            )}
            {visibleGLoading && (
              <Generating
                ref={generatingRef}
                cancel={() => {
                  handleCancel();
                }}
              />
            )}

            {visibleGResult && (
              // 重写生成
              <GenerationResult
                reGenerate={newSelection => {
                  // if (!writeEssayParam || !tmpContent || tmpContent === '') {
                  //   return;
                  // }

                  //  AI写作
                  if (writingType === WritingType.AIWriting) {
                    handleWriteEssay();
                    asyncCalculateToolbarTop();
                  }

                  // 扩写 、 改写 、 翻译
                  if (
                    writingType === WritingType.EXPANSION ||
                    writingType === WritingType.REWRITE ||
                    writingType === WritingType.SUMMARIZE ||
                    writingType === WritingType.TRANSLATE
                  ) {
                    startCalculationTimer();
                    generateByToolBar(
                      writeEssayParam.selectedContent!,
                      writingType,
                      writeEssayParam.lang,
                      writeEssayParam.content,
                      writeEssayParam.selectedContentStartIdx,
                      writeEssayParam.selectedContentEndIdx,
                    );
                  }

                  // 续写
                  if (writingType === WritingType.CONTINUATION) {
                    generateByContinuation(
                      writeEssayParam.selectedContent!,
                      writingType,
                      writeEssayParam.content,
                      writeEssayParam.selectedContentStartIdx,
                      writeEssayParam.selectedContentEndIdx,
                      newSelection,
                    );
                    asyncCalculateToolbarTop();
                  }
                }}
                onCancel={handleCancel}
              />
            )}
          </div>
        ) : (
          // 翻译 /续写
          <GenerateMenuToolbar
            menuToolbarClick={(w, l, p, network) => {
              handleMenuToolbar(w, l, p, network);
            }}
          />
        )}
      </>
    </EscKeyListener>
  );
});

export default GenerateWrapper;
