import ResourcesModal, { ActionType, Resource } from '@components/upload-modal';

import { FC, useState } from 'react';
import { ModalProps, TabsProps } from '@arco-design/web-react';
import { useModel } from '@modern-js/runtime/model';
import GlobalStore from '@/store/globalStore';
import useLocale from '@/hooks/useLocale';

interface GlobalResourcesModalProps {
  modalProps?: ModalProps;
  tabsProps?: TabsProps;
  tabs?: ActionType[];
  defalutValue?: Resource[];
  onConfirm?: (value: Resource[]) => void;
  onUpload?: (value: Resource[]) => void;
  onChange?: (data: Resource[]) => void;
}
const GlobalResourcesModal: FC<GlobalResourcesModalProps> = ({ modalProps = {}, tabsProps = {}, tabs, defalutValue, onChange, onUpload, onConfirm }) => {
  const locale = useLocale();
  const [{ resources = [], selectFiles = [], selectReferences = [] }, { setAddResources }] = useModel(GlobalStore);
  const [activeTab, setActiveTab] = useState<ActionType>(Number(tabsProps.defaultActiveTab));
  console.log('GlobalResourcesModal---->', selectFiles, 'selectReferences', selectReferences);

  return (
    <ResourcesModal
      modalProps={modalProps}
      tabsProps={{
        ...tabsProps,
        activeTab: tabsProps.activeTab || `${activeTab}`,
        onChange: value => {
          setActiveTab(() => Number(value));
          tabsProps?.onChange?.(value);
        },
      }}
      extraMessage={locale.upload_modal_tab_limit_text}
      tabs={tabs || [ActionType.UPLOAD_ATTACHMENT, ActionType.ADD_LINK]}
      resources={resources}
      defaultValue={defalutValue || [...selectFiles, ...selectReferences]?.map(item => ({ id: item.uuid, name: item.name, type: item.type })) || []}
      onConfirm={(values: Resource[]) => {
        // setSelectFiles(values.filter(item => item.type === ResourceType.FILE) || []);
        // setSelectReferences(values.filter(item => item.type === ResourceType.ATTACHMENT) || []);
        modalProps?.onCancel?.();
        onConfirm?.(values);
      }}
      onUpload={(file: Resource[]) => {
        console.log('add file', file);
        setAddResources([...resources, ...file]);
        onUpload?.(file);
      }}
      onChange={(files: Resource[]) => {
        setAddResources(files);
        onChange?.(files);
      }}
    />
  );
};

export default GlobalResourcesModal;
