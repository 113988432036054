import './index.scss';

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from '@modern-js/runtime/model';
import classNames from 'classnames';
import { useLocation } from '@modern-js/runtime/router';
import { IconToBottom } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import IconClear from '@arco-iconbox/react-hkgai-government-icon/cjs/IconClear';
import { Divider, Modal } from '@arco-design/web-react';
import GenerateWrapper, { GenerateWrapperRef } from './components/generate-wrapper';
import DocEditor, { DocEditorRef } from './components/doc-editor';
import Footer from './components/footer';
import { countWords, saveFileFromBuffer } from './util';
import BtnAskAI from './components/btn-ask-ai';
import Proofreading from '@/components/proofreading';
import { WritingProvider } from '@/context/writingContext';
import useUrlParams from '@/hooks/useUrlParams';
import EditorStore from '@/store/editorStore';
import { WritingType } from '@/type/ui';
import useLocale from '@/hooks/useLocale';
import { RequireAuth } from '@/routes/auth';
import SidebarContent from '@/components/sidebar-content';
import Feedback from '@/components/feedback';
import { GlobalContext } from '@/routes/context';
import { fetchDownloadFile } from '@/api/copilot_api';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';
import { AiBotEvent, ModuleName, PageEvent, PageNameConfig } from '@/config/track.config';
import SidebarDrawer from '@/components/sidebar-drawer';
import { SidebarType } from '@/type/copilot';
import AiChatBot from '@/components/ai-chat-bot';
import GlobalStore from '@/store/globalStore';

// import ProofreadingBar from '@/components/proofreading/bar';
import useControlAIWritingInput from '@/hooks/useControlAIWritingInput';

const Index = () => {
  const locale = useLocale();
  const { newChat } = useUrlParams();
  const documentVisible = useVisibilityChange();
  const timeRef = useRef(dayjs().unix());
  const aiBotTimeRef = useRef(dayjs().unix());
  const [{ sideBar = [] }] = useModel(GlobalStore);

  const { collectEvent } = useContext(GlobalContext);

  const location = useLocation();
  //   const titleInputRef = useRef<RefInputType>(null);
  const [sidebarType, setSidebarType] = useState<SidebarType>(SidebarType.UNKNOWN);

  const [aiBotVisible, setAiBotVisible] = useState(false);
  const [{ editorContent, editorSize, visibleGInput, writingParamEntered, isAIWork }, { clearEditorContent, setWritingType, setIsAIWork }] = useModel(EditorStore);

  const [activeAiBot, setActiveAiBot] = useState(false);

  const docEditorRef = useRef<DocEditorRef>(null);
  const generateWrapperRef = useRef<GenerateWrapperRef>(null);

  const pathname = location.state?.from?.pathname;
  const from = pathname || '/writing';
  const pageName = PageNameConfig.WRITING_PAGE;
  const fromLink = pathname ? from : '';

  useEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink,
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink,
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  useEffect(() => {
    clearEditorContent();
    setIsAIWork(false);
    setWritingType(WritingType.UNKNOWN);
    // if (titleInputRef?.current) {
    //   titleInputRef.current.focus();
    // }
  }, [newChat, location]);

  useEffect(() => {
    return () => {
      clearEditorContent();
    };
  }, []);

  const { open: openAIWriting, close: closeAIWriting, setWritingSence } = useControlAIWritingInput();

  const handleSidebarClick = (type: SidebarType) => {
    setSidebarType(oldType => (oldType === type ? SidebarType.UNKNOWN : type));
    // AI 写作
    if (type === SidebarType.COPILOT) {
      const isAIWritingInputOpen = visibleGInput;
      const keepOpening = isAIWritingInputOpen && writingParamEntered;
      if (keepOpening) {
        return;
      }

      // 打开行内 写作引导框体
      if (!isAIWritingInputOpen) {
        openAIWriting();
      } else {
        // 关闭写作框体
        closeAIWriting();
      }
      setWritingSence('');
      return;
    }
    closeAIWriting();
  };

  const handleDownloadFile = async () => {
    try {
      if (!editorContent?.plain?.length) {
        return;
      }
      const fileKey = `${dayjs().valueOf()}`;
      console.log('editorChildren', JSON.stringify(editorContent));
      const data = await fetchDownloadFile(fileKey, editorContent?.html || '');
      saveFileFromBuffer(data, `HKCopilot_${fileKey}`);
    } catch (error) {}
  };

  const handleCloseSidebar = () => {
    setSidebarType(() => SidebarType.UNKNOWN);
  };

  const handleClearEditorContext = () => {
    Modal.confirm({
      autoFocus: false,
      title: locale['writing.confirm.modal.title'],
      content: <div className="text-center">{locale['writing.confirm_modal.content']}</div>,
      okText: locale['writing.confirm_modal.button.confirm'],
      cancelText: locale['writing.confirm.modal.button.cancel'],
      okButtonProps: {
        status: 'danger',
      },
      onOk: () => {
        setIsAIWork(false);
        setWritingType(WritingType.UNKNOWN);
        docEditorRef?.current?.resetEditorContent();
      },
    });
  };

  const renderSidBar = useMemo(() => {
    switch (sidebarType) {
      case SidebarType.PROOFREADING:
        return <Proofreading />;
      case SidebarType.FEEDBACK:
        return <Feedback />;
      default:
        return <></>;
    }

    // return sidebarMap[sidebarType];
  }, [sidebarType]);

  return (
    <RequireAuth>
      <>
        <WritingProvider>
          <div className="writing-wrap">
            <div className="writing-main">
              <div className="writing-actions text-right">
                <div className={classNames('icon-button clear-btn', { 'icon-button-dis': !editorContent?.plain?.length })} onClick={handleClearEditorContext}>
                  <IconClear />
                  <div>{locale['writing.clear.button']}</div>
                </div>
                <div className={classNames('icon-button download-btn', { 'icon-button-dis': !editorContent?.plain?.length })} onClick={handleDownloadFile}>
                  <IconToBottom />
                  <div>{locale['writing.download.text']}</div>
                </div>

                <div className="word-count">
                  <div style={{ color: 'rgba(204, 204, 204, 1)' }} className="select-none">
                    {locale['writing.wordCount']}：
                  </div>
                  <div style={{ width: '60px', textAlign: 'left', color: !editorContent?.plain?.length ? 'rgba(204, 204, 204, 1)' : ' rgba(34, 34, 34, 1)' }}>
                    {countWords(editorContent?.plain || '')}
                  </div>
                </div>
              </div>

              <div className={classNames('writing-body')} id="writing-body">
                <div id="editor-wrap" className={classNames('editor-wrap', { 'small-size': editorSize === 'small' })}>
                  <div className="sticky top-0 bg-white z-10">
                    <BtnAskAI btnAskAIClick={() => handleSidebarClick(SidebarType.COPILOT)} />
                    <Divider />
                  </div>

                  <DocEditor ref={docEditorRef} />
                  <GenerateWrapper ref={generateWrapperRef} />
                </div>
              </div>
              <Footer />
            </div>

            <div className="sidebar-content ">
              <SidebarDrawer visible={sidebarType !== SidebarType.UNKNOWN && sidebarType !== SidebarType.COPILOT} onClose={handleCloseSidebar}>
                {renderSidBar}
              </SidebarDrawer>
              <SidebarContent
                sideBar={sideBar}
                isAiBotActivate={activeAiBot}
                type={sidebarType}
                onClick={handleSidebarClick}
                onAiChat={() => {
                  setActiveAiBot(oldStatus => !oldStatus);
                  setAiBotVisible(oldStatus => {
                    if (oldStatus) {
                      collectEvent?.([
                        {
                          event: AiBotEvent.CLICK_HIDE_AI_BOT,
                          params: {
                            moduleName: ModuleName.AI_BOT,
                            duration: `${dayjs().unix() - aiBotTimeRef.current}`,
                          },
                        },
                      ]);
                    } else {
                      aiBotTimeRef.current = dayjs().unix();
                      collectEvent?.([
                        {
                          event: AiBotEvent.CLICK_SHOW_AI_BOT,
                          params: {
                            moduleName: ModuleName.AI_BOT,
                          },
                        },
                      ]);
                    }
                    return !oldStatus;
                  });
                }}
              />
            </div>
          </div>
        </WritingProvider>
        <AiChatBot
          visible={aiBotVisible}
          onClose={() => {
            setAiBotVisible(() => false);
            setActiveAiBot(() => false);
            collectEvent?.([
              {
                event: AiBotEvent.CLICK_HIDE_AI_BOT,
                params: {
                  moduleName: ModuleName.AI_BOT,
                  duration: `${dayjs().unix() - aiBotTimeRef.current}`,
                },
              },
            ]);
          }}
        />
      </>
    </RequireAuth>
  );
};

export default Index;
