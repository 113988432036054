import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Message, Modal } from '@arco-design/web-react';
import defaultLocale from '../locale';
import { ErrorCode } from './errno';
import { ContentType } from '@/api/request';
import { AIWorkStatus } from '@/type/ui';
import { InstructionKeyPairs } from '@/type/copilot';
import { clearGenerateParam, setGenerateParam } from '@/utils/generateParam';

const locale = (key: string): string => {
  const lang = localStorage.getItem('arco-lang') || 'en-US';
  return defaultLocale[lang][key];
};

const redirectToLogin = () => {
  if (window.location.href.indexOf('/login') >= 0) {
    return;
  }
  window.location.replace('/login');
};

// 负责取消fetchEventSource请求的控制器
let ctrl: AbortController;

const IDEA_HEADERS = () => {
  return {
    'Content-Type': ContentType.JSON,
    'X-App-Id': '2',
  };
};
let authFailedModalShown = false;

const handleSSEOpen = async (response: Response, onCallBack?: () => void) => {
  if (response.ok) {
    if (response.headers.get('content-type') === 'text/event-stream') {
      console.log('Connection opened successfully');
      console.log('response.headers====》', response.headers.get('content-type'));
    } else if (response.headers.get('content-type') === 'application/json; charset=utf-8') {
      const resp = await response.json();
      const respCode = resp.code;
      if (respCode === ErrorCode.NOT_LOGIN) {
        localStorage.clear();
        sessionStorage.clear();
        redirectToLogin();
        return;
      }
      // 未激活
      if (respCode === ErrorCode.H_ERR_USER_UNACTIVATE) {
        Message.error(locale('login_err_user_unactivate'));
        window.location.replace('/pwd/reset');
        return;
      }
      //   禁用
      if (respCode === ErrorCode.H_ERR_USER_BLOCKED) {
        Message.error(locale('login_user_blocked'));
        redirectToLogin();
        return;
      }
      //   3个月密码过期
      if (respCode === ErrorCode.H_ERR_USER_CHANGE_CYCLE) {
        if (!authFailedModalShown) {
          authFailedModalShown = true;
          Modal.info({
            autoFocus: false,
            title: locale('exit_modal_password_expired_content'),
            okText: locale('exit_modal_password_expired_button'),
            onOk: () => {
              authFailedModalShown = false;
              window.open('/pwd/reset');
            },
            afterClose: () => {
              authFailedModalShown = false;
            },
          });
        }
        return;
      }
      onCallBack?.();
      abortGenerate();
    }
  } else if (response.status !== 200) {
    onCallBack?.();
    console.error('Request error', response);
  }
};

const transformInstructionKeyPairs = (originalJson: { [key: string]: any }) => {
  const instructionKeyPairs: InstructionKeyPairs[] = [];
  for (const key in originalJson) {
    instructionKeyPairs.push({
      key,
      value: originalJson[key],
    });
  }

  return instructionKeyPairs;
};

type CallbackFn = (value: string | any, status?: AIWorkStatus) => void;

interface POption {
  word_num?: string; // 用户选择字数
  reply_language?: string; // 用户选择生成语言
  user_instruction?: string; // 用户输入指令
  uploaded_ref?: string; // 上传附件/link解析内容
  user_ref?: string; // 用户复制粘贴参考内容
  exist_article?: string; // 编辑板全部内容
  exist_article_former?: string; // 编辑板位于光标前内容
  exist_article_latter?: string; // 编辑板位于光标后内容
  selected_content?: string; // 选中文字
  /*
    Speech (演讲稿)
    Report (报告，可以用于工作总结)
    Presentation (演示文稿)
    Memo (备忘录)
    Proposal (提案)
    Review (评审，可以用于工作总结或报告)
    Essay (论文，有时也用于表达个人观点或分析)
    Article (文章，用于发表观点或信息)
    Summary (摘要或总结，可以是工作总结的一种形式)
    WorkReport (工作总结)
  */
  document_types?: string; // 文章的类型或者写作的场景
  keywords?: string; // 关键字
  selected_start?: string; // 选中内容在整篇文章中的位置-起始索引
  selected_end?: string; //  选中内容在整篇文章中的位置-结束索引
}

const ideaTokenStr = (startTime: number, firstTime: number, endTime: number, tokenCounts: number) => {
  console.log(
    `Output: ${tokenCounts}tokens; TTFT: ${parseFloat((Math.abs(firstTime - startTime) / 1000).toFixed(2))}s; ` +
      `TPOT: ${parseFloat((tokenCounts / Math.ceil(Math.abs(endTime - firstTime) / 1000)).toFixed(2))}t/s; ` +
      `Latency: ${parseFloat((Math.abs(endTime - startTime) / 1000).toFixed(2))}s;` +
      `TPS: ${parseFloat((tokenCounts / Math.ceil(Math.abs(endTime - firstTime) / 1000)).toFixed(2))}t/s.`,
  );
};

const defaultPOption = {
  word_num: '',
  reply_language: '',
  user_instruction: '',
  uploaded_ref: '',
  user_ref: '',
  exist_article: '',
  exist_article_former: '',
  exist_article_latter: '',
  selected_content: '',
};

export const generateMessage = (key: string, options: { [key: string]: string }, callback: CallbackFn) => {
  const startTime = new Date().getTime();
  let firstTime = 0;
  let endTime = 0;
  let tokenCounts = 0;
  const parameters = transformInstructionKeyPairs({ ...defaultPOption, ...options });
  clearGenerateParam();
  setGenerateParam({ key, parameters, stream: true });
  ctrl = new AbortController();
  fetchEventSource('/v2/instruction/completion', {
    method: 'POST',
    headers: IDEA_HEADERS(),
    body: JSON.stringify({
      key,
      parameters,
      stream: true,
    }),
    openWhenHidden: true,
    signal: ctrl.signal,
    async onopen(response) {
      handleSSEOpen(response);
    },
    async onmessage(resp) {
      if (firstTime === 0) {
        firstTime = new Date().getTime();
      }

      if (resp.event === 'FINISH' || resp.event === 'CANCEL') {
        endTime = new Date().getTime();
        ideaTokenStr(startTime, firstTime, endTime, tokenCounts);
        callback({}, AIWorkStatus.COMPLETED);
        ctrl.abort();
        return;
      }
      if (resp.event === 'ERROR') {
        console.error('instruction/completion api error, resp is', JSON.stringify(resp));
        callback('{}', AIWorkStatus.CHATError);
        ctrl.abort();
        return;
      }

      if (resp.data) {
        const data = JSON.parse(resp.data || '{}');
        if (data.choices[0].delta.content) {
          setGenerateParam({ model: data.model, generate_id: data.id });
          tokenCounts++;
          callback(data.choices[0].delta.content, undefined);
        }
      }
    },
    onclose() {
      console.log('Connection closed');
      callback('{}', AIWorkStatus.COMPLETED);
    },
  });
};

export const abortGenerate = () => {
  ctrl.abort();
};

// 写一篇文章
export const fullArticle = (
  inputPrompt: string,
  replyLanguage: string,
  wordNum: number,
  brainstorm: string,
  callback: CallbackFn,
  fullText?: string,
  userRef?: string,
  sence?: string,
  enableNetworkSearch?: boolean,
) => {
  const options: POption = {
    user_instruction: inputPrompt,
    // reply_language: replyLanguage,
    // word_num: String(wordNum),
    uploaded_ref: brainstorm,
    exist_article: fullText,
    user_ref: userRef,
    document_types: sence,
  };

  generateMessage(enableNetworkSearch ? 'ai_writing_with_search' : 'ai_writing', options as { [key: string]: string }, callback);
};

// 扩写指定段落
export const expansionParagraph = (
  selectedText: string,
  callback: CallbackFn,
  fullText?: string,
  inputPrompt?: string,
  brainstorm?: string,
  startIdx?: number,
  endIdx?: number,
) => {
  const options: POption = {
    selected_content: selectedText,
    user_instruction: inputPrompt,
    exist_article: fullText,
    uploaded_ref: brainstorm,
    selected_start: String(startIdx),
    selected_end: String(endIdx),
  };

  generateMessage('expend_selected', options as { [key: string]: string }, callback);
};

// 续写指定段落
export const continuationParagraph = (
  selectedText: string,
  callback: CallbackFn,
  fullText?: string,
  inputPrompt?: string,
  brainstorm?: string,
  startIdx?: number,
  endIdx?: number,
  enableNetworkSearch?: boolean
) => {
  const options: POption = {
    selected_content: selectedText,
    user_instruction: inputPrompt,
    exist_article: fullText,
    uploaded_ref: brainstorm,
    selected_start: String(startIdx),
    selected_end: String(endIdx),
  };
  generateMessage(enableNetworkSearch ? 'continue_writing_with_search' : 'continue_writing', options as { [key: string]: string }, callback);
};

// 翻译指定内容
export const translateParagraph = (
  selectedText: string,
  replyLanguage: string,
  callback: CallbackFn,
  fullText?: string,
  brainstorm?: string,
  inputPrompt?: string,
  startIdx?: number,
  endIdx?: number,
) => {
  const options: POption = {
    selected_content: selectedText,
    reply_language: replyLanguage,
    exist_article: fullText,
    uploaded_ref: brainstorm,
    user_instruction: inputPrompt,
    selected_start: String(startIdx),
    selected_end: String(endIdx),
  };
  generateMessage('translate_selected', options as { [key: string]: string }, callback);
};

// 重写选中的文字
export const rewriteParagraph = (selectedText: string, callback: CallbackFn, fullText?: string, inputPrompt?: string, brainstorm?: string, startIdx?: number, endIdx?: number,  enableNetworkSearch?: boolean) => {
  const options: POption = {
    selected_content: selectedText,
    user_instruction: inputPrompt,
    exist_article: fullText,
    uploaded_ref: brainstorm,
    selected_start: String(startIdx),
    selected_end: String(endIdx),
  };

  generateMessage(enableNetworkSearch ? 'rewrite_selected_with_search' : 'rewrite_selected', options as { [key: string]: string }, callback);
};

// 总结指定段落
export const summarizeParagraph = (
  selectedText: string,
  callback: CallbackFn,
  fullText?: string,
  inputPrompt?: string,
  brainstorm?: string,
  startIdx?: number,
  endIdx?: number,
) => {
  const options: POption = {
    selected_content: selectedText,
    user_instruction: inputPrompt,
    exist_article: fullText,
    uploaded_ref: brainstorm,
    selected_start: String(startIdx),
    selected_end: String(endIdx),
  };
  generateMessage('summarize_selected', options as { [key: string]: string }, callback);
};

// ai bot
interface ChatMessagesReq {
  massageId?: string;
  content?: string;
  text?: string;
  conversationId?: string;
}

type AiBotCallbackFn = (
  data: { value?: string | any; conversationId?: string; massageId?: string; startTime?: number; firstTime?: number; endTime?: number; totalTokens?: number; totalSteps?: number },
  status?: AIWorkStatus,
) => void;

let aiBotCtrl: AbortController;

export const abortAiBotGenerate = () => {
  aiBotCtrl.abort();
};

export const fetchAIbot = (params: ChatMessagesReq, callback: AiBotCallbackFn) => {
  const options = {
    massage_id: params?.massageId || '',
    query: params.content,
    inputs: { doc_text: params.text || '' },
    key: 'PROMPT_CHAT_BOT',
    stream: true,
    conversation_id: params.conversationId,
  };

  const startTime = new Date().getTime();
  let firstTime = 0;
  let endTime = 0;
  let totalTokens = 0;
  let totalSteps = 0;
  aiBotCtrl = new AbortController();
  let conversationId = '0';
  fetchEventSource('/v1/chat_messages', {
    method: 'POST',
    headers: { ...IDEA_HEADERS(), data_clean: '1' },
    body: JSON.stringify({
      ...options,
    }),
    openWhenHidden: true,
    signal: aiBotCtrl.signal,

    async onopen(response) {
      handleSSEOpen(response, () => {
        callback({}, AIWorkStatus.CHATError);
      });
    },
    async onmessage(resp) {
      if (firstTime === 0) {
        firstTime = new Date().getTime();
      }

      if (resp.event === 'WORKFLOW_FINISHED') {
        const data = JSON.parse(resp.data || '{}');
        totalTokens = data?.total_tokens || 0;
        totalSteps = data?.total_steps || 0;
      }

      if (resp.event === 'FINISH' || resp.event === 'CANCEL') {
        endTime = new Date().getTime();
        ideaTokenStr(startTime, firstTime, endTime, totalTokens);
        callback({ startTime, firstTime, endTime, totalTokens }, AIWorkStatus.COMPLETED);
        aiBotCtrl.abort();
        return;
      }
      if (resp.event === 'ERROR') {
        console.error('/v1/chat_messages api error, resp is', JSON.stringify(resp));
        callback({ startTime, firstTime, endTime, totalTokens }, AIWorkStatus.CHATError);
        aiBotCtrl.abort();
        return;
      }

      if (resp.data && resp.event === 'APPEND') {
        const data = JSON.parse(resp.data || '{}');
        totalTokens = data?.total_tokens || 0;
        conversationId = data?.conversation_id && data?.conversation_id;
        const choices = data?.choices || [];
        if (choices?.[0]?.delta.content) {
          callback(
            {
              value: choices?.[0].delta.content,
              conversationId,
              massageId: data.id,
              startTime,
              firstTime,
              endTime,
              totalTokens,
            },
            AIWorkStatus.WORKING,
          );
        }
      }
    },
    onclose() {
      console.log('Connection closed');
    },
  });
};
