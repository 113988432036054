import { model } from '@modern-js/runtime/model';
import { Range } from '@hkgai/slate-plugin/dist/lib/delta';
import { WritingType, AIWorkStatus } from '@/type/ui';

export interface WriteEssayParam {
  content?: string; // 用户输入的文字
  style?: string;
  lang?: string;
  wordNum?: number;
  references?: string[]; // 用户添加的link
  fileText?: string[]; // 用户上传的file转换的文字
  selectedContent?: string; // 用户选中的内容
  user_ref?: string; // 用户复制粘贴参考内容
  sence?: string; // 写作场景
  selectedContentStartIdx?: number; // 选中的文本在全文中的起始索引位置
  selectedContentEndIdx?: number; // 选中的文本在全文中的结束索引位置
  enable_network_search?: boolean;
}

export interface EditorContent {
  html?: string;
  plain?: string;
  slateDom?: string;
}

interface Props {
  workStatus?: AIWorkStatus; // AI生成状态
  writingType?: WritingType; // 写作(创作)类型
  generatingText: string; // 正在生成中的文本
  generateTop?: number; // 生成过程中，toolbar top的高度
  isAIWork?: boolean; // 是不是处于AI工作中
  writeEssayParam: WriteEssayParam;
  editorContent?: EditorContent;
  btnAIOpenStatus?: boolean; // AI按钮状态 (理论上来说现在这个状态没再用到了，用到action和state的部分都被干掉了)
  editorSize?: 'big' | 'small'; // 编辑器的大小
  editorSelection?: Range; // 编辑器选中的位置，和editor.selection不一定严格相同，这里只是为了在某些点选操作中防止失焦造成获取不到操作前的selection而暂存的
  isBuiltInPrompt?: boolean; // 是否使用内置的prompt
}

const EditorStore = model<Props>('EditorStore').define({
  state: {
    workStatus: AIWorkStatus.NOT_WORKING,
    writingType: WritingType.UNKNOWN,
    generatingText: '',
    isAIWork: false,
    writeEssayParam: {
      content: '',
      style: '',
      lang: 'English',
      wordNum: 500,
      references: [],
      fileText: [],
      selectedContent: '',
      user_ref: '',
      sence: '',
      enable_network_search: false,
    },
    editorContent: { html: '', plain: '' },
    btnAIOpenStatus: false,
    editorValue: [{ text: '' }],
    editorSize: 'big',
    generateingTextInsertLocation: null,
    uploadFiles: [],
    selectUploadFiles: [],
    editorSelection: null,
    isBuiltInPrompt: false,
  },
  actions: {
    changeWorkStatus(draft, payload: AIWorkStatus) {
      draft.workStatus = payload;
    },
    changeWritingType(draft, payload: WritingType) {
      draft.writingType = payload;
    },
    setGeneratingText(draft, payload: string) {
      draft.generatingText += payload;
    },
    resetGeneratingText(draft) {
      draft.generatingText = '';
    },
    setGenerateTop(draft, payload: number) {
      if (draft.generateTop !== payload) {
        draft.generateTop = payload;
      }
    },
    setIsAIWork(draft, payload: boolean) {
      draft.isAIWork = payload;
    },
    setWriteEssayParam(draft, payload: WriteEssayParam) {
      draft.writeEssayParam = { ...draft.writeEssayParam, ...payload };
    },
    clearWriteEssayParam(draft) {
      draft.writeEssayParam = { content: '', style: '', lang: 'English', wordNum: 500, references: [], fileText: [], selectedContent: '', user_ref: '', sence: '' };
    },
    setEditorContent(draft, payload: EditorContent) {
      draft.editorContent = { ...draft.editorContent, ...payload };
    },
    clearEditorContent(draft) {
      draft.editorContent = { html: '', plain: '' };
    },
    changeBtnAIOpenStatus(draft, payload: boolean) {
      draft.btnAIOpenStatus = payload;
    },
    changeEditorSize(draft, payload: 'big' | 'small') {
      draft.editorSize = payload;
    },
    changeEditorSelection(draft, payload?: Range) {
      draft.editorSelection = payload;
    },
    setIsBuiltInPrompt(draft, payload) {
      draft.isBuiltInPrompt = payload;
    },
  },
  computed: {
    visibleGInput: state => {
      return state.writingType === WritingType.AIWriting && state.workStatus === AIWorkStatus.NOT_WORKING;
    },
    visibleGLoading: state => {
      return (
        (state.writingType === WritingType.AIWriting || state.writingType === WritingType.CONTINUATION) &&
        (state.workStatus === AIWorkStatus.WORK_PENDING || state.workStatus === AIWorkStatus.WORKING)
      );
    },
    visibleGResult: state => {
      if (state.workStatus === AIWorkStatus.COMPLETED) {
        return true;
      }
      if (
        state.writingType === WritingType.EXPANSION ||
        state.writingType === WritingType.REWRITE ||
        state.writingType === WritingType.SUMMARIZE ||
        state.writingType === WritingType.TRANSLATE
      ) {
        return state.workStatus === AIWorkStatus.WORK_PENDING || state.workStatus === AIWorkStatus.WORKING;
      }
      return false;
    },
    editorReadonly: state => {
      return state.isAIWork;
    },
    writingParamEntered: state => {
      const { writeEssayParam } = state;
      return Boolean(writeEssayParam.content || writeEssayParam.fileText?.length || writeEssayParam.references?.length || writeEssayParam.user_ref?.length);
    },
  },
});

export default EditorStore;
