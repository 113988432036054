import { Button, Form, Input, Modal } from '@arco-design/web-react';
import { FC } from 'react';
import { IconClose } from '@arco-design/web-react/icon';
import useLocale from '@/hooks/useLocale';

const formItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const Index: FC<{ onCancel?: () => void; onOk?: (formValues?: any) => Promise<void> }> = ({ onCancel, onOk }) => {
  const [form] = Form.useForm();
  const locale = useLocale();
  const reportConfirm = () => {
    Modal.confirm({
      title: locale['privacy.modal.report.title'],
      // eslint-disable-next-line react/no-danger
      content: <div style={{ width: '400px' }} dangerouslySetInnerHTML={{ __html: locale['privacy.modal.report.content'] }}></div>,
      okText: locale['privacy.modal.report.submit'],
      cancelText: locale['privacy.modal.report.cancel'],
      onCancel: () => {
        // form.resetFields();
        // onCancel?.();
      },
      onOk: async () => {
        const formValues = form.getFieldsValue();
        await onOk?.(formValues);
      },
    });
  };
  return (
    <div className="reportDdl">
      <div className="reportHeader w-full justify-between">
        <div>{locale['report.modal.header']}</div>
        <div
          role="button"
          onClick={e => {
            e.stopPropagation();
            onCancel?.();
          }}
        >
          <IconClose />
        </div>
      </div>
      <Form
        {...formItemLayout}
        form={form}
        onValuesChange={(_, vs) => {
          console.log(vs);
        }}
      >
        <Form.Item className="textarea" field="remark">
          <Input.TextArea
            placeholder={locale['report.modal.content.placeholder']}
            onPressEnter={e => {
              e.stopPropagation();
              e.preventDefault();

              if (!form.getFieldValue('remark')) {
                return;
              }
              reportConfirm();
            }}
          />
        </Form.Item>
        <Form.Item className="btnBox" shouldUpdate>
          {values => (
            <Button type="primary" onClick={reportConfirm} disabled={!values?.remark}>
              {locale['report.modal.button.text']}
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Index;
