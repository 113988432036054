import './index.scss';

import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Dropdown, Input, Menu, Message, Switch } from '@arco-design/web-react';
import { IconCheck } from '@arco-iconbox/react-hkgai-fe-icon';
import { useModel } from '@modern-js/runtime/model';
import { IconWarningCircle } from '@arco-iconbox/react-hkgai-government-icon';
import { debounce, throttle } from 'lodash-es';
import { RefInputType } from '@arco-design/web-react/es/Input';
import { IconDown } from '@arco-design/web-react/icon';
import { countWords } from '../../util';
import FeedbackPopover from '../feedback-popover';
import AttachmentToolbar from '../attachment-toolbar';
import { contiueWritingBuildinPrompts, rewriteBuildinPrompts, summaryBuildinPrompts } from './const';
import useLocale from '@/hooks/useLocale';
import { WritingType, WritingTypeText } from '@/type/ui';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import { chatResultReport } from '@/api/copilot_api';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';
import { GlobalContext } from '@/routes/context';
import Attachment from '@/components/attachment';
import { Resource } from '@/components/upload-modal';
import { ToolbarPromptHistory } from '@/components/promptHistory';
import PromptHistoryStore from '@/store/promptHistoryStore';
import GlobalStore from '@/store/globalStore';
import { Sidebars, SidebarStatus, SidebarType } from '@/type/copilot';
import { MAX_USER_PROMPT_LEN } from '@/config/constant';

const writingTypeLocaleKeyMap: Partial<Record<WritingType, string>> = {
  [WritingType.CONTINUATION]: 'ai.writing.type.continuation',
  [WritingType.REWRITE]: 'ai.writing.type.rewrite',
  [WritingType.SUMMARIZE]: 'ai.writing.type.summarize',
  [WritingType.TRANSLATE]: 'ai.writing.type.translate',
};

interface MenuToolbarProps {
  menuToolbarClick?: (w: WritingType, l?: string, p?: string, network?: boolean) => void;
}
const Index: FC<MenuToolbarProps> = ({ menuToolbarClick }) => {
  const [{ sideBar = [] }] = useModel(GlobalStore);

  const locale = useLocale();
  const { collectEvent, lang } = useContext(GlobalContext);

  const [language, setLanguage] = useState<string>('');
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { editor, editorKit } = useWritingContext();
  const [{ writingType, editorSize }, { changeWritingType, changeEditorSelection, setWriteEssayParam, setIsBuiltInPrompt }] = useModel(EditorStore);
  const [ddlVisible, setDdlVisible] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');
  const [selectedWritingType, setSelectedWritingType] = useState<WritingType>(WritingType.UNKNOWN);
  const [promptContent, setPromptContent] = useState('');
  const [referenceLinkList, setReferenceLinkList] = useState<string[]>([]);
  const [uploadFiles, setUploadFiles] = useState<Resource[]>([]);
  const promptInputRef = useRef<RefInputType>(null);
  const [enableNetworkSearch, setEnableNetworkSearch] = useState(false);
  const showNetworkSearchSwitch = useMemo(() => ![WritingType.TRANSLATE, WritingType.SUMMARIZE].includes(selectedWritingType), [selectedWritingType]);

  const [, { addtoolbarPromptHistory }] = useModel(PromptHistoryStore);

  const handleClickType = (type: WritingType) => {
    setSelectedWritingType(type);
    changeEditorSelection(editor?.selection || undefined);
  };

  const startAction = () => {
    if (selectedWritingType === WritingType.UNKNOWN) {
      return;
    }

    changeWritingType?.(selectedWritingType);

    menuToolbarClick?.(selectedWritingType, language, promptContent, showNetworkSearchSwitch && enableNetworkSearch);
    // 存一下prompt history
    addtoolbarPromptHistory({ writingType, prompt: promptContent });
  };

  const clearUp = () => {
    setPromptContent('');
    setSelectedWritingType(WritingType.UNKNOWN);
    setUploadFiles([]);
    setReferenceLinkList([]);
  };

  const isToolBarDisplayRef = useRef(false);

  const calculateToolbarTop = () => {
    const selectionDom = window.getSelection();
    const toolbarDom = toolbarRef.current;
    const editorWrap = document.getElementById('editor-wrap');
    const writingBodyDom = document.getElementById('writing-body');
    if (selectionDom && toolbarDom && editorWrap && writingBodyDom) {
      const selectionRange = selectionDom?.getRangeAt(0);
      const selectionRect = selectionRange?.getBoundingClientRect();
      const editorWrapRect = editorWrap.getBoundingClientRect();
      const toolbarRect = toolbarDom.getBoundingClientRect();
      const writingBodyRect = writingBodyDom?.getBoundingClientRect();

      const selectedContent = selectionDom?.toString().replace('\uFEFF', ''); // 去除BOM字符

      if (selectionRect && selectedContent?.length) {
        // 计算选中内容相对editorWrap的位置
        const editorTop = editorWrapRect.top;

        const selectionTop = selectionRect.top;

        const selectionRelativeTop = selectionTop - editorTop;

        const top = selectionRect.height + selectionRelativeTop;
        // 工具栏定位的最大高度，选中时要保证工具栏在视窗内
        const maxTop = writingBodyRect.height - toolbarRect.height + writingBodyDom.scrollTop - 80;
        toolbarDom.style.top = `${Math.min(top, maxTop) + 8}px`;
        if (top > maxTop) {
          toolbarDom.style.transition = '200ms linear';
        } else {
          toolbarDom.style.transition = 'unset';
        }
        toolbarDom.style.opacity = '1';
        setSelectedContent(selectedContent || '');
      }
    }
  };

  const handlerSelectionChange = () => {
    const el = toolbarRef.current;
    const editorWrap = document.getElementById('editor-wrap');
    const editorContainer = document.getElementById('editor-container')?.querySelector('[role=textbox]');

    if (!el || !editor || !editorWrap || !editorContainer) {
      return;
    }
    const sel = window.getSelection();
    // 如果反馈弹框打开，那操作栏的位置先不变
    if (ddlVisible) {
      return;
    }

    // 如果是prompt输入框体获取焦点 或者焦点在工具栏上 则不关闭。
    if (promptInputRef.current?.dom === document.activeElement || el.contains(document.activeElement)) {
      return;
    }

    const isSelectionOutOfToolbar = sel?.focusNode && !el.contains(sel.focusNode);
    const isSelectionOutOfEditor =
      ((sel?.focusNode && !editorContainer.contains(sel.focusNode)) || (sel?.anchorNode && !editorContainer.contains(sel.anchorNode))) && isSelectionOutOfToolbar;

    if (isSelectionOutOfEditor || (sel?.isCollapsed && isSelectionOutOfToolbar)) {
      el.removeAttribute('style');
      isToolBarDisplayRef.current = false;
      clearUp();
    } else {
      calculateToolbarTop();
      const domRange = sel?.getRangeAt(0);
      const rect = domRange?.getBoundingClientRect();
      const editorWrapRect = editorWrap.getBoundingClientRect();

      const selectedContent = sel?.toString().replace('\uFEFF', ''); // 去除BOM字符

      if (rect && selectedContent?.length) {
        // 计算选中内容相对editorWrap的位置
        const editorTop = editorWrapRect.top;
        const selectionTop = rect.top;
        const selectionRelativeTop = selectionTop - editorTop;
        const top = rect.height + selectionRelativeTop;
        el.style.top = `${top + 8}px`;
        el.style.opacity = '1';
        isToolBarDisplayRef.current = true;
        setSelectedContent(selectedContent || '');
      }
    }
  };

  useEffect(() => {
    const writingBodyDom = document.getElementById('writing-body');

    // 滚动时调整位置
    const onScroll = throttle(function (this: HTMLElement, event: Event) {
      if (isToolBarDisplayRef.current) {
        calculateToolbarTop();
      }
    }, 200);

    writingBodyDom?.addEventListener?.('scroll', onScroll);
    return function () {
      writingBodyDom?.removeEventListener?.('scroll', onScroll);
    };
  }, []);

  const showAttachmentToolbar = useMemo(() => {
    const config = sideBar?.find((item: Sidebars) => item.type === (101 as SidebarType));
    console.log('showAttachmentToolbar config', config, sideBar);
    return config?.status === SidebarStatus.ENABLED;
  }, [sideBar.length]);

  useEffect(() => {
    if (!showAttachmentToolbar) {
      setReferenceLinkList([]);
      setUploadFiles([]);
    }
  }, [showAttachmentToolbar]);

  // const handlerCopy = () => {
  //   const { plain } = getSelectionContent(editorKit!);

  //   if (plain && plain.length > 0) {
  //     collectEvent?.([
  //       {
  //         event: WritingPageEvent.CLICK_COPY,
  //         params: {
  //           pageName: PageNameConfig.WRITING_PAGE,
  //           moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
  //           action: WritingTypeText[writingType || WritingType.UNKNOWN],
  //         },
  //       },
  //     ]);
  //     copyTextUsingClipboardAPI(plain, () => Message.success({ content: locale['copy.success.toast'] }));
  //   }
  // };

  const feedBackDropList = (
    <FeedbackPopover
      onCancel={() => setDdlVisible(false)}
      onOk={async (formValues: any) => {
        const { remark = '' } = formValues || {};
        const param: Record<string, any> = {};
        if (!param) {
          return;
        }

        if (selectedContent) {
          param.comments = [remark];
          param.result = selectedContent;
          param.generate_id = '';
          param.parameters = [];
          await chatResultReport(param);
          setDdlVisible(() => false);
          Message.success(locale.feedback_submitted_message);
        }
      }}
    />
  );

  useEffect(() => {
    const debounced = debounce(handlerSelectionChange, 200);
    document.addEventListener('selectionchange', debounced);
    return () => {
      document.removeEventListener('selectionchange', debounced);
    };
  }, [editor, ddlVisible]);

  // 更新store中的上传文件和链接
  useEffect(() => {
    setWriteEssayParam({
      fileText: uploadFiles.map(file => file.value || ''),
    });
  }, [uploadFiles]);

  useEffect(() => {
    setWriteEssayParam({
      references: referenceLinkList,
    });
  }, [referenceLinkList]);

  const onClickMenuItem = (key: string) => {
    const [subMenuKey, itemKey] = key.split('_');
    const writingType = Number(subMenuKey);

    if (writingType !== WritingType.UNKNOWN) {
      changeWritingType?.(writingType);
    }
    changeEditorSelection(editor?.selection || undefined);

    // 改写 续写 总结
    if ([WritingType.REWRITE, WritingType.CONTINUATION, WritingType.SUMMARIZE].includes(writingType)) {
      setPromptContent(locale[itemKey] || '');
      setLanguage(() => '');
    }
    if ([WritingType.TRANSLATE].includes(writingType)) {
      setLanguage(itemKey);
      setPromptContent('');
    }
    handleClickType(writingType);
  };

  const actionDisabled = useMemo(() => selectedWritingType === WritingType.UNKNOWN, [selectedWritingType]);

  useEffect(() => {
    promptInputRef.current?.focus();
  }, [selectedWritingType, promptContent]);
  return (
    <>
      <div ref={toolbarRef} className={classNames('menu-toolbar-wrap')}>
        <div
          onMouseDown={e => {
            // 反馈弹框显示时需要光标可以聚焦
            if (!ddlVisible) {
              e.preventDefault();
            }
          }}
          className="space-y-3"
        >
          <div className="flex items-center justify-between">
            <div style={{ fontSize: '12px' }}>
              <span className="text-[#CCCCCC] mr-1">{locale['ai.writing.selection.count']}:</span>
              <span>{countWords(selectedContent || '')}</span>
            </div>

            <Dropdown droplist={feedBackDropList} position="top" popupVisible={ddlVisible}>
              <div
                onClick={() => {
                  setDdlVisible(() => !ddlVisible);
                }}
                className="text-[#666666] flex items-center gap-1 cursor-pointer"
              >
                <IconWarningCircle />
                {locale['ai.writing.generate.button.report']}
              </div>
            </Dropdown>
          </div>
          <div className="flex items-center gap-2">
            <Input
              ref={promptInputRef}
              className={'prompt-input'}
              placeholder={actionDisabled ? '' : locale['ai.writing.prompt.input.placeholder']}
              onChange={v => {
                const val = v.length <= MAX_USER_PROMPT_LEN ? v : v.slice(0, MAX_USER_PROMPT_LEN);
                setPromptContent(() => val);
              }}
              value={promptContent}
              onPressEnter={startAction}
              disabled={actionDisabled}
              prefix={
                <Dropdown
                  droplist={
                    <Menu onClickMenuItem={onClickMenuItem}>
                      <Menu.SubMenu
                        key={`${WritingType.REWRITE}`}
                        title={locale[writingTypeLocaleKeyMap[WritingType.REWRITE] as string]}
                        triggerProps={{ popupStyle: { maxHeight: 'fit-content' } }}
                      >
                        {rewriteBuildinPrompts.map(item => (
                          <Menu.Item key={`${WritingType.REWRITE}_${item.value}`}>{locale[item.label]}</Menu.Item>
                        ))}
                      </Menu.SubMenu>

                      <Menu.SubMenu key={`${WritingType.CONTINUATION}`} title={locale[writingTypeLocaleKeyMap[WritingType.CONTINUATION] as string]}>
                        {contiueWritingBuildinPrompts.map(item => (
                          <Menu.Item key={`${WritingType.CONTINUATION}_${item.value}`}>{locale[item.label]}</Menu.Item>
                        ))}
                      </Menu.SubMenu>

                      {/* <Menu.SubMenu key={`${WritingType.EXPANSION}`} title={locale['ai.writing.type.expansion']}>
                      {expansionBuildinPrompts.map(item => (
                        <Menu.Item key={`${WritingType.EXPANSION}_${item.value}`}>{locale[item.label]}</Menu.Item>
                      ))}
                      <Menu.Item key={`${WritingType.EXPANSION}_`}>{locale['ai.writing.type.customize']}</Menu.Item>
                    </Menu.SubMenu> */}

                      <Menu.SubMenu key={`${WritingType.SUMMARIZE}`} title={locale[writingTypeLocaleKeyMap[WritingType.SUMMARIZE] as string]}>
                        {summaryBuildinPrompts.map(item => (
                          <Menu.Item key={`${WritingType.SUMMARIZE}_${item.value}`}>{locale[item.label]}</Menu.Item>
                        ))}
                      </Menu.SubMenu>

                      <Menu.SubMenu key={`${WritingType.TRANSLATE}`} title={locale[writingTypeLocaleKeyMap[WritingType.TRANSLATE] as string]}>
                        {[
                          { label: 'ai.writing.translate.english', value: 'English' },
                          { label: 'ai.writing.translate.simplified', value: '简体' },
                          { label: 'ai.writing.translate.traditional', value: '繁體' },
                        ].map(({ value: ll, label }) => (
                          <Menu.Item key={`${WritingType.TRANSLATE}_${ll}`} className={classNames({ current: language === ll })}>
                            <div className="flex items-center">
                              <div className="flex w-5 h-5 items-center justify-center">{language === ll && <IconCheck className="w-3 h-3" />}</div>
                              <div className="text">{locale[label]}</div>
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>

                      {/* <Menu.Item key={`${WritingType.CONTINUATION}`}>{locale['ai.writing.type.continuation']}</Menu.Item> */}
                    </Menu>
                  }
                  position="bl"
                >
                  <div className={classNames('count-btn')}>
                    {locale[writingTypeLocaleKeyMap[selectedWritingType] as string] || locale['ai.writing.type.entry']}
                    <IconDown />
                  </div>
                </Dropdown>
              }
              suffix={
                <div className={actionDisabled ? 'select-none pointer-events-none' : ''}>
                  <ToolbarPromptHistory
                    onChange={val => {
                      setPromptContent(val);
                      collectEvent?.([
                        {
                          event: WritingPageEvent.CLICK_TOOLBAR_PROMPT_HISTORY,
                          params: {
                            pageName: PageNameConfig.WRITING_PAGE,
                            moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
                            action: WritingTypeText[writingType || WritingType.UNKNOWN],
                          },
                        },
                      ]);
                    }}
                    writingType={selectedWritingType}
                  />
                </div>
              }
              onMouseDown={e => e.stopPropagation()}
            />

            <Button
              type="primary"
              className="btn_confirm rounded-lg"
              onClick={() => {
                startAction();
              }}
              disabled={actionDisabled}
            >
              {locale['ai.writing.prompt.button']}
            </Button>
          </div>

          {showAttachmentToolbar && (
            <div className="menu">
              {/** 总结和翻译的工作流没有联网 */}
              {showNetworkSearchSwitch && (
                <div className="mr-[10px] flex items-center gap-1">
                  <Switch checked={enableNetworkSearch} onChange={checked => setEnableNetworkSearch(checked)} />
                  <span>{locale['ai.writing.input.enable_network_search']}</span>
                </div>
              )}
              <AttachmentToolbar
                onChange={(files, links) => {
                  setUploadFiles(files);
                  setReferenceLinkList(links.map(item => item.name || ''));
                }}
              />

              <div className="flex gap-4 flex-nowrap flex-1 w-full overflow-hidden">
                {uploadFiles.map(file => (
                  <div key={file.uuid} style={{ maxWidth: `calc(${100 / (uploadFiles.length + referenceLinkList.length)}% - 16px)`, width: 'fit-content' }}>
                    <Attachment
                      key={file.uuid}
                      title={file.name}
                      type="file"
                      onClose={() => {
                        setUploadFiles(preFiles => preFiles.filter(item => item.uuid !== file.uuid));
                      }}
                    />
                  </div>
                ))}
                {referenceLinkList.map(link => (
                  <div key={link} style={{ maxWidth: `calc(${100 / (uploadFiles.length + referenceLinkList.length)}% - 16px)`, width: 'fit-content' }}>
                    <Attachment
                      key={link}
                      title={link}
                      type="link"
                      onClose={() => {
                        setReferenceLinkList(preLinks => preLinks.filter(item => item !== link));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
