import './index.scss';

import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useLocale from '@hooks/useLocale';
import { Button, Dropdown, Message, Tooltip } from '@arco-design/web-react';
import { BaseRange, Editor, ReactEditor, Transforms } from '@hkgai/slate-plugin/dist/lib/delta';
import { useModel } from '@modern-js/runtime/model';
import { IconCheck, IconInfo } from '@arco-iconbox/react-hkgai-fe-icon';
import { IconWarningCircle, IconCopy, IconArrowClockwise, IconTrash } from '@arco-iconbox/react-hkgai-government-icon';
import classNames from 'classnames';
import { IconLeft } from '@arco-design/web-react/icon';
import { RefTextAreaType } from '@arco-design/web-react/es/Input';
import { copyTextUsingClipboardAPI, countWords } from '../../util';
import FeedbackPopover from '../feedback-popover';
import Generating from '../generating';
import InputTextArea from '../input-textarea';
import { WritingType, AIWorkStatus, WritingTypeText } from '@/type/ui';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import { getGenerateParam } from '@/utils/generateParam';
import { chatResultReport } from '@/api/copilot_api';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';
import { GlobalContext } from '@/routes/context';
import { MAX_USER_PROMPT_LEN } from '@/config/constant';
import PromptHistoryStore from '@/store/promptHistoryStore';

interface GenerationResultProps {
  reGenerate?: (newSelection?: BaseRange) => void;
  onCancel?: () => void;
}

const GenerationResult: FC<GenerationResultProps> = ({ reGenerate, onCancel }) => {
  const locale = useLocale();
  const textareaRef = useRef<RefTextAreaType>(null);

  const { editor } = useWritingContext();
  const { collectEvent } = useContext(GlobalContext);

  const [
    { workStatus, writingType, generatingText, editorSelection, writeEssayParam, isBuiltInPrompt },
    { changeWorkStatus, setWritingType, setIsAIWork, resetGeneratingText, changeEditorSelection, setWriteEssayParam },
  ] = useModel(EditorStore);
  const [, { addAIWritingPromptHistory, addtoolbarPromptHistory }] = useModel(PromptHistoryStore);

  const [ddlVisible, setDdlVisible] = useState<boolean>(false);
  const [promptInputReadonly, setPromptReadonly] = useState(true);

  useEffect(() => {
    const resultContainer = document.getElementById('generation-result');
    if (generatingText !== '' && resultContainer) {
      resultContainer.scrollTop = resultContainer.scrollHeight;
    }
  }, [generatingText]);

  const generationResultHint = useMemo(
    () => (
      <div className="generation-result-hint">
        <div className="flex gap-20 justify-between">
          <div className="prompt-message">
            <span className="whitespace-nowrap">{locale['ai.writing.generate.count']}:</span>
            <span className="ml-1" style={{ color: '#666' }}>
              {countWords(generatingText)}
            </span>
          </div>
          <Tooltip content={locale['ai.writing.generate.warning']}>
            <div className="flex items-center overflow-hidden">
              <IconInfo style={{ fontSize: '12px', verticalAlign: '-3', flex: 'none' }} />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">{locale['ai.writing.generate.warning']}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    ),
    [locale, generatingText],
  );

  const applyResult = () => {
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    if ((writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) && editor) {
      if (editorSelection) {
        ReactEditor.focus(editor);
        Transforms.select(editor, editorSelection);
      }
    } else {
      const currentSelection = editorSelection;
      if (currentSelection && editor) {
        const [startPoint, endPoint] = editor.edges(currentSelection);
        let insertPoint = endPoint;
        // 删除之前选中的内容
        if (writingType !== WritingType.TRANSLATE) {
          Transforms.insertText(editor, '', {
            at: {
              anchor: startPoint,
              focus: endPoint,
            },
          });
          // 从选中内容的起始位置插入
          insertPoint = startPoint;
        }
        let { path: insertAtPath, offset: insertAtOffset } = insertPoint;

        // 插入生成的内容
        generatingText.split('\n').forEach((tmpText, index) => {
          // index > 0 说明有\n需要换行； 或者 写作类型等于翻译的时候 也换行
          if (index > 0 || writingType === WritingType.TRANSLATE) {
            Transforms.insertNodes(
              editor,
              {
                children: [{ text: tmpText }],
              },
              { at: { path: insertAtPath, offset: insertAtOffset } },
            );

            insertAtPath = [insertAtPath[0] + 1, 0];
            insertAtOffset = tmpText.length;
            // 记录翻译开始插入的位置
            if (index === 0) {
              insertPoint = { path: insertAtPath, offset: 0 };
            }
          }
          // 当index 为0 且写作类型不是 翻译的时候，直接追加
          if (index === 0 && writingType !== WritingType.TRANSLATE) {
            Transforms.insertText(editor, tmpText, { at: insertPoint });
            insertAtOffset += tmpText.length;
          }
        });
        // 选中生成的内容
        ReactEditor.focus(editor);
        Transforms.select(editor, {
          anchor: insertPoint,
          focus: { path: insertAtPath, offset: insertAtOffset },
        });
        changeEditorSelection({ anchor: insertPoint, focus: { path: insertAtPath, offset: insertAtOffset } });
      }
    }
    collectEvent?.([
      {
        event: WritingPageEvent.CLICK_AI_WRITING,
        params: {
          pageName: PageNameConfig.WRITING_PAGE,
          moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
          action: WritingTypeText[writingType || WritingType.UNKNOWN],
        },
      },
    ]);

    setIsAIWork?.(false);
    setWritingType(WritingType.UNKNOWN);
  };

  const onCopyText = (e: any) => {
    e.stopPropagation();
    copyTextUsingClipboardAPI(generatingText, () => Message.success(locale['copy.success.toast']));
    collectEvent?.([
      {
        event: WritingPageEvent.CLICK_COPY,
        params: {
          pageName: PageNameConfig.WRITING_PAGE,
          moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
          action: WritingTypeText[writingType || WritingType.UNKNOWN],
        },
      },
    ]);
  };

  const reGenerateContent = () => {
    setPromptReadonly(true);
    let newSelection = editorSelection;
    if (writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) {
      const [startPoint, endPoint] = Editor.edges(editor!, editorSelection!);
      // 删除内容
      Transforms.delete(editor!, { at: editorSelection });
      // 重置选中区域
      newSelection = {
        anchor: startPoint,
        focus: startPoint,
      };
      changeEditorSelection(newSelection);
    }

    if (newSelection) {
      ReactEditor.focus(editor!);
      Transforms.select(editor!, newSelection);
    }
    // changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    // setIsAIWork?.(false);

    resetGeneratingText();
    // 新的selection临时这样处理一下，不然函数作用域有闭包，拿不到model里最新的editorSelection
    reGenerate?.(newSelection);
    if (writingType === WritingType.AIWriting) {
      addAIWritingPromptHistory(writeEssayParam.content);
    } else {
      addtoolbarPromptHistory({
        writingType,
        prompt: writeEssayParam.content,
      });
    }
  };

  useEffect(() => {
    if (!promptInputReadonly) {
      textareaRef.current?.focus();
      const rangeEnd = textareaRef.current?.dom.value.length || 0;
      textareaRef.current?.dom.setSelectionRange(rangeEnd, rangeEnd);
    }
  }, [promptInputReadonly]);

  return (
    <div className="generation-result-wrapper ">
      <div className="generation-toolbar">
        {writingType !== WritingType.AIWriting && writingType !== WritingType.CONTINUATION && (
          <div className={classNames('generation-result', { complete: workStatus === AIWorkStatus.COMPLETED })}>
            <div className="generation-result-content" id="generation-result">
              {generatingText}
              {/* <TextLoading show={workStatus === AIWorkStatus.WORKING} /> */}
            </div>
          </div>
        )}
        <div className="flex items-center justify-between gap-3">
          <div className="flex-1 overflow-hidden">{generationResultHint}</div>
          <div className="prompt-report">
            <Dropdown
              droplist={
                <FeedbackPopover
                  onCancel={() => {
                    setDdlVisible(() => false);
                  }}
                  onOk={async formValues => {
                    // const { comments = [], remark = '' } = form.getFieldsValue();
                    // if (comments.length <= 0 && remark === '') {
                    //   return;
                    // }

                    // const index = comments.findIndex((c: string) => c === 'Other');
                    // if (index >= 0) {
                    //   comments.splice(index, 1, remark);
                    // }

                    const { remark = '' } = formValues || {};
                    const param = getGenerateParam();
                    if (!param) {
                      return;
                    }
                    param.comments = [remark];
                    await chatResultReport(param);
                    setDdlVisible(() => false);
                    Message.success(locale.feedback_submitted_message);
                  }}
                />
              }
              position="top"
              popupVisible={ddlVisible}
            >
              <div
                className="text-[#666] items-center flex gap-1 text-xs cursor-pointer"
                onClick={() => {
                  setDdlVisible(() => !ddlVisible);
                }}
              >
                <IconWarningCircle color="#666" />
                {locale['ai.writing.generate.button.report']}
              </div>
            </Dropdown>
          </div>
        </div>
        {workStatus === AIWorkStatus.COMPLETED && (
          <div className="menu">
            <div className="w-full flex gap-2 items-center overflow-hidden">
              {promptInputReadonly ? (
                <>
                  {/* click apply  */}
                  <div
                    className="btn-done"
                    onClick={e => {
                      e.stopPropagation();
                      applyResult();
                    }}
                  >
                    <IconCheck />
                    {locale['ai.writing.generate.button.use']}
                  </div>
                  {/* click copy */}
                  <div className="btn-toolbar" onClick={onCopyText}>
                    <IconCopy />
                  </div>
                  <div
                    className="btn-toolbar"
                    onClick={e => {
                      e.stopPropagation();
                      reGenerateContent();
                    }}
                  >
                    <IconArrowClockwise />
                  </div>
                  {/* click delete */}
                  <div
                    className="btn-toolbar"
                    onClick={e => {
                      e.stopPropagation();
                      changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
                      // 定位光标到最初选择的位置
                      ReactEditor.focus(editor!);
                      const [startPoint, endPoint] = Editor.edges(editor!, editorSelection!);

                      if (writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) {
                        Transforms.delete(editor!, { at: editorSelection });
                        Transforms.select(editor!, startPoint);
                        changeEditorSelection({ anchor: startPoint, focus: startPoint });
                      }

                      if (
                        writingType === WritingType.EXPANSION ||
                        writingType === WritingType.REWRITE ||
                        writingType === WritingType.SUMMARIZE ||
                        writingType === WritingType.TRANSLATE
                      ) {
                        Transforms.select(editor!, endPoint);
                        changeEditorSelection({ anchor: endPoint, focus: endPoint });
                      }
                      setIsAIWork?.(false);
                      setWritingType(WritingType.UNKNOWN);
                      resetGeneratingText();
                    }}
                  >
                    <IconTrash />
                  </div>
                  <div className="flex items-center ml-6 flex-1 overflow-hidden">
                    <span className="whitespace-nowrap mr-2">{locale['ai.writing.generate.prompt']}</span>
                    <div
                      className="h-9 flex items-center cursor-pointer border-[#E5E5E5] border-solid px-[10px] rounded overflow-hidden flex-1"
                      onClick={e => {
                        setPromptReadonly(false);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div className="text-ellipsis overflow-hidden whitespace-nowrap w-full">{writeEssayParam.content}</div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex w-full">
                  <IconLeft
                    className="cursor-pointer w-5 h-5 mr-2 mt-[6px] hover:bg-gray-100"
                    onClick={() => {
                      setPromptReadonly(true);
                      const textareaDom = textareaRef.current?.dom;
                      textareaDom?.setSelectionRange?.(0, 0);
                    }}
                  />
                  <InputTextArea
                    autoSize
                    ref={textareaRef}
                    readOnly={promptInputReadonly}
                    value={writeEssayParam.content}
                    onChange={val => {
                      let value = val;
                      if (val.length >= MAX_USER_PROMPT_LEN) {
                        value = val.slice(0, MAX_USER_PROMPT_LEN);
                      }
                      setWriteEssayParam({ content: value });
                    }}
                    onPressEnter={e => {
                      e.stopPropagation();
                      if (e.shiftKey) {
                        return;
                      }
                      reGenerateContent();
                    }}
                    className={`rounded leading-6 min-h-9 ${promptInputReadonly ? 'max-h-8' : 'max-h-80'}`}
                  />
                  <Button
                    type="primary"
                    className="rounded ml-2 h-9"
                    onClick={e => {
                      e.stopPropagation();
                      reGenerateContent();
                    }}
                  >
                    {locale['ai.writing.generate.tooltip.restart']}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {(workStatus === AIWorkStatus.WORK_PENDING || workStatus === AIWorkStatus.WORKING) && <Generating cancel={onCancel} />}
      </div>
    </div>
  );
};

export default GenerationResult;
