import './index.scss';

import classNames from 'classnames';
import { createElement, FC, useMemo } from 'react';
import * as allIcons from '@arco-iconbox/react-hkgai-government-icon';
import { Trigger } from '@arco-design/web-react';
import { IconArticle, IconUserSound } from '@arco-iconbox/react-hkgai-government-icon';
import useLocale from '@/hooks/useLocale';
import { Sidebars, SidebarStatus, SidebarType } from '@/type/copilot';

interface SidebarContentProps {
  type: SidebarType;
  isAiBotActivate?: boolean;
  sideBar: Sidebars[];
  onClick?: (type: SidebarType) => void;
  onAiChat?: () => void;
}

const SidebarContent: FC<SidebarContentProps> = ({ type = SidebarType.UNKNOWN, sideBar = [], isAiBotActivate = false, onClick, onAiChat }) => {
  const locale = useLocale();

  const CreateIcon = (props: { icon: string }) => {
    const { icon } = props;
    const renderIcon: { [key: string]: any } = allIcons;
    return createElement(renderIcon[icon]);
  };

  const [chatbotMenuInfo, feedbackMenuInfo, proofReadingCMenu, writingFlowMenu]: { [key: string]: any }[] = useMemo(() => {
    // ai bot
    const chatbotConfig = sideBar.find(item => item.type === SidebarType.CHATBOT);
    const chatbotMenu = chatbotConfig?.status === SidebarStatus.ENABLED ? { ...chatbotConfig, label: locale.sidebar_ai_chat_bot, icon: 'IconAiBot' } : {};
    //  feedback
    const feedbackConfig = sideBar.find(item => item.type === SidebarType.FEEDBACK);
    const feedbackMenu = feedbackConfig?.status === SidebarStatus.ENABLED ? { ...feedbackConfig, label: locale.sidebar_ai_feedback, icon: 'IconAiFeedback' } : {};
    // ai 校对
    const proofReadingConfig = sideBar.find(item => item.type === SidebarType.PROOFREADING);
    const proofReadingCMenu = proofReadingConfig?.status === SidebarStatus.ENABLED ? { ...proofReadingConfig, label: locale.sidebar_ai_proof_reading, icon: 'IconAiFile' } : {};
    // 工作流
    const writingFlowConfig = sideBar.find(item => item.type === SidebarType.WRITING_FLOW);
    const writingFlowMenu = writingFlowConfig?.status === SidebarStatus.ENABLED ? { ...writingFlowConfig, label: locale.sidebar_writing_flow, icon: 'IconOutlineWriting' } : {};

    return [chatbotMenu, feedbackMenu, proofReadingCMenu, writingFlowMenu];
  }, [sideBar.length, locale]);

  return (
    <div className={classNames('sidebar-toolbar', { 'sidebar-toolbar-left-border': type !== SidebarType.UNKNOWN })}>
      {/* <div
        className={classNames('sidebar-toolbar__item', { 'sidebar-toolbar__item-active': SidebarType.COPILOT === type })}
        onClick={e => {
          e.stopPropagation();
          onClick?.(SidebarType.COPILOT);
        }}
      >
        <div className="icon-box">
          <CreateIcon icon="IconAiCopilot" />
        </div>
        <div className="sidebar-toolbar__item-text">{locale.sidebar_ai_writing}</div>
      </div> */}

      <div className="sidebar-list">
        {writingFlowMenu.label && (
          <Trigger
            showArrow
            position="left"
            popupAlign={{
              left: 20,
            }}
            popup={() => (
              <div className="sub-menu" onClick={() => onClick?.(writingFlowMenu.type)} key={writingFlowMenu.type}>
                <div className="sub-menu__item">
                  <IconUserSound />
                  演讲稿
                </div>
                <div className="sub-menu__item">
                  <IconArticle />
                  工作总结
                </div>
              </div>
            )}
          >
            <div
              id={writingFlowMenu.id}
              className={classNames('sidebar-toolbar__item', { 'sidebar-toolbar__item-active': writingFlowMenu.type === type })}
              key={writingFlowMenu.type}
            >
              <div className="icon-box">{writingFlowMenu.icon && <CreateIcon icon={writingFlowMenu.icon} />}</div>
              <div className="sidebar-toolbar__item-text">{writingFlowMenu.label}</div>
            </div>
          </Trigger>
        )}

        {/* AI 校对 */}
        {proofReadingCMenu?.label && (
          <div
            className={classNames('sidebar-toolbar__item', { 'sidebar-toolbar__item-active': proofReadingCMenu?.type === type })}
            key={proofReadingCMenu?.type}
            onClick={e => {
              e.stopPropagation();
              onClick?.(proofReadingCMenu.type);
            }}
          >
            <div className="icon-box">{proofReadingCMenu.icon && <CreateIcon icon={proofReadingCMenu.icon} />}</div>
            <div className="sidebar-toolbar__item-text">{proofReadingCMenu.label}</div>
          </div>
        )}
        {/*  feedback */}
        {feedbackMenuInfo?.label && (
          <div
            className={classNames('sidebar-toolbar__item', { 'sidebar-toolbar__item-active': feedbackMenuInfo?.type === type })}
            key={feedbackMenuInfo?.type}
            onClick={e => {
              e.stopPropagation();
              onClick?.(feedbackMenuInfo.type);
            }}
          >
            <div className="icon-box">{feedbackMenuInfo.icon && <CreateIcon icon={feedbackMenuInfo.icon} />}</div>
            <div className="sidebar-toolbar__item-text">{feedbackMenuInfo.label}</div>
          </div>
        )}
      </div>
      {/* ai bot */}
      {chatbotMenuInfo.label && (
        <div
          onClick={e => {
            e.stopPropagation();
            onAiChat?.();
          }}
          className={classNames('chatbot-menu-info', { 'chatbot-menu-info-active': isAiBotActivate })}
          key={chatbotMenuInfo.type}
        >
          <div className="icon-box">{chatbotMenuInfo.icon && <CreateIcon icon={chatbotMenuInfo.icon} />}</div>
          <div className="chatbot-menu-info-text">{chatbotMenuInfo.label}</div>
        </div>
      )}
    </div>
  );
};

export default SidebarContent;
// Subscribe
