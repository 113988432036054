import { Descendant } from 'slate';
import { PROOFREAD_LINE_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import { PCCategory, PCMatchParagraph, PCSubcategoryCommon, PCSubcategoryProperNoun } from '@/type/proofread';

// 前端手动排序
export const sortMatchItems = (matchItems: PCMatchParagraph[]) => {
  return matchItems.sort((a, b) => {
    // 前端兜底排序
    if (a.paragraph === b.paragraph) {
      return (a.offset || 0) - (b.offset || 0);
    }
    return a.paragraph - b.paragraph;
  });
};

export const calculateLocation = (children: Descendant[] = [], matchItem?: PCMatchParagraph) => {
  const anchorPath0 = matchItem?.paragraph || 0;
  const focusPath0 = matchItem?.paragraph || 0;
  let anchorPath1 = 0;
  let focusPath1 = 0;
  let anchorOffset = 0;
  let focusOffset = 0;

  let strLen = 0; // 文本总长度

  const arr = children[anchorPath0].children || [];

  for (let index = 0, len = arr.length; index < len; index++) {
    const element = arr[index];
    const l = element.text?.length || 0; // children里面，当前循环下，文本的长度
    const offset = matchItem?.offset || 0; // 校对结果中，在当前段落中的总偏移量
    const length = matchItem?.length || 0; // 校对的文本的长度
    if (offset >= strLen && offset < strLen + l) {
      // offset > strLen的时候，说明校对的文本在这个item里面或后面，&& offset <= strLen + l，校对的文本的起始点落在这个item里面
      anchorPath1 = index;
      // 在这个block里面strLen代表这个item前面文本的总长度
      anchorOffset = offset - strLen;
    }
    if (offset + length > strLen && offset + length <= strLen + l) {
      // 判断校对的文本的终点落在这个item里面
      focusPath1 = index;
      focusOffset = anchorOffset + length;
    }
    strLen += l;
  }

  return {
    anchor: { path: [anchorPath0, anchorPath1], offset: anchorOffset },
    focus: { path: [focusPath0, focusPath1], offset: focusOffset },
  };
};

export const calculateLocationId = (children: Descendant[] = [], id = '') => {
  let anchorPath0 = 0;
  let anchorPath1 = 0;
  let currTextLength = 0;
  // const anchorOffset = 0;
  // const focusOffset = 0;

  try {
    children.forEach((item, index) => {
      item.children?.forEach((i, j) => {
        // @ts-expect-error
        const tid = i?.[PROOFREAD_LINE_KEY]?.id;
        if (tid === id) {
          anchorPath0 = index;
          anchorPath1 = j;
          currTextLength = i.text?.length || 0;
          throw new Error('break foreach');
        }
      });
    });
  } catch (error) {}

  return {
    anchor: { path: [anchorPath0, anchorPath1], offset: 0 },
    focus: { path: [anchorPath0, anchorPath1], offset: currTextLength },
  };
};

export const matchName = (locale: any, name?: string) => {
  switch (name) {
    case PCCategory.COMMON_ERRORS:
      return locale.proofreading_common_errors;
    case PCCategory.PROPER_NAMES:
      return locale.proofreading_proper_names;
    case PCSubcategoryCommon.EVENTS:
      return locale.proofreading_events;
    case PCSubcategoryCommon.ORGANIZATIONS:
      return locale.proofreading_organizations;
    case PCSubcategoryCommon.PERSONS:
      return locale.proofreading_persons;
    case PCSubcategoryProperNoun.PUNCTUATION:
      return locale.proofreading_punctuation;
    case PCSubcategoryProperNoun.TYPO:
      return locale.proofreading_typo;
    case PCSubcategoryProperNoun.GRAMMAR:
      return locale.proofreading_grammar;
    default:
      return '';
  }
};
