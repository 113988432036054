import type { EditorSchema } from '@hkgai/slate-plugin/dist/lib/core';
import {
  TABLE_BLOCK_KEY,
  TABLE_CELL_BLOCK_KEY,
  TABLE_ROW_BLOCK_KEY,
  DIVIDING_LINE_KEY,
  UNORDERED_LIST_ITEM_KEY,
  UNORDERED_LIST_KEY,
  ORDERED_LIST_ITEM_KEY,
  ORDERED_LIST_KEY,
  QUOTE_BLOCK_ITEM_KEY,
  QUOTE_BLOCK_KEY,
} from '@hkgai/slate-plugin/dist/lib/plugins';

export const schema: EditorSchema = {
  [DIVIDING_LINE_KEY]: {
    void: true,
    block: true,
  },
  [QUOTE_BLOCK_ITEM_KEY]: {
    wrap: QUOTE_BLOCK_KEY,
  },
  [ORDERED_LIST_ITEM_KEY]: {
    wrap: ORDERED_LIST_KEY,
  },
  [UNORDERED_LIST_ITEM_KEY]: {
    wrap: UNORDERED_LIST_KEY,
  },
  [TABLE_ROW_BLOCK_KEY]: {
    wrap: TABLE_BLOCK_KEY,
  },
  [TABLE_CELL_BLOCK_KEY]: {
    wrap: TABLE_ROW_BLOCK_KEY,
    instance: true,
  },
};
