import { model } from '@modern-js/runtime/model';
import { WritingType } from '@/type/ui';

interface Props {
  writingPromptHistory: Array<string>;
  toolbarPromptHistory: Partial<Record<WritingType, Array<string>>>;
}

const PromptHistoryStore = model<Props>('PromptHistoryStore').define({
  state: {
    writingPromptHistory: [],
    toolbarPromptHistory: {},
  },
  actions: {
    addAIWritingPromptHistory(draft, data) {
      const history = draft.writingPromptHistory.filter(item => item !== data);
      history.unshift(data);
      draft.writingPromptHistory = history;
    },
    removeAIWritingPromptHistory(draft, data) {
      const history = draft.writingPromptHistory.filter(item => item !== data);
      draft.writingPromptHistory = history;
    },
    addtoolbarPromptHistory(draft, data) {
      const writingType = data.writingType as WritingType;
      const { prompt } = data;
      const history = draft.toolbarPromptHistory[writingType]?.filter?.(item => item !== prompt) || [];
      history.unshift(prompt);
      draft.toolbarPromptHistory = {
        ...draft.toolbarPromptHistory,
        [writingType]: history,
      };
    },
    removeToolbarPromptHistory(draft, data) {
      const writingType = data.writingType as WritingType;
      const { prompt } = data;
      const history = draft.toolbarPromptHistory[writingType]?.filter?.(item => item !== prompt) || [];
      draft.toolbarPromptHistory = {
        ...draft.toolbarPromptHistory,
        [writingType]: history,
      };
    },
  },
  computed: {
    // fileList: state => {
    //   return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.FILE)) || [];
    // },
    // references: state => {
    //   return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.ATTACHMENT)) || [];
    // },
  },
});

export default PromptHistoryStore;
